import styled, { css } from "styled-components"

import { Property } from "csstype"

import { divider } from "src/ui/colors"
import ExpandIcon from "src/ui/icons/chevron-down.svg"
import { SubtitleMixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type TMDetailType = "card" | "accordion" | "default"

type TControlledState =
  | {
      open: boolean
      onChange: (wasOpen: boolean) => void
    }
  | {
      open?: undefined
      onChange?: undefined
    }

export type MDetailsProps = {
  id?: string // id is used if we want to link internally inside a page to this component
  title: React.ReactNode
  children: React.ReactNode
  variant?: TMDetailType
  summaryProps?: {
    bgColor?: string
    fgColor?: string
    cardPaddingOverride?: Property.Padding<string | number>
  }
  icon?: React.ReactNode
} & TControlledState

export function MDetails({
  id,
  title,
  children,
  open,
  onChange,
  variant = "default",
  summaryProps,
  icon,
}: MDetailsProps) {
  return (
    <Details
      id={id}
      open={open}
      onClick={(e: React.MouseEvent<HTMLDetailsElement>) => {
        e.stopPropagation()
      }}
      $summaryFgColor={summaryProps?.fgColor ?? "inherit"}
      $summaryBgColor={summaryProps?.bgColor ?? "inherit"}
      $cardPaddingOverride={summaryProps?.cardPaddingOverride}
      className={getClassname(variant)}
    >
      <summary
        onClick={(e: React.MouseEvent<HTMLDetailsElement>) => {
          if (!onChange) return // uncontrolled component, let browser handle click

          e.preventDefault()
          e.stopPropagation()
          if (e.target instanceof HTMLButtonElement) {
            return // ignore events bubbling up from buttons
          }
          onChange(e.currentTarget.open)
        }}
      >
        <div className="chevron-box">{icon ?? <ExpandIcon width={15} />}</div>

        {title}
      </summary>

      <div className="details-box" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </Details>
  )
}

const cardBorder = css`
  box-shadow: // left side
    inset 1px 0px 0px ${divider},
    // right side
    inset -1px 0px 0px ${divider},
    // top side
    inset 0px 1px 0px ${divider},
    // bottom side
    inset 0px -1px 0px ${divider};
`

function getClassname(variant: TMDetailType) {
  return `mdetails-${variant}` as const
}

const Details = styled.details<{
  $summaryBgColor: string
  $summaryFgColor: string
  $cardPaddingOverride?: Property.Padding<string | number>
}>`
  border-radius: 8px;
  overflow: hidden;

  &.${getClassname("card")} {
    ${cardBorder};
  }

  &[open] > .details-box {
    animation: animateDown 0.2s linear forwards;
  }

  & .chevron-box {
    transition: all 0.2s ease-in;
    margin-right: ${spacing.S};
  }
  &:not([open]) .chevron-box {
    transform: rotate(-90deg);
  }

  & > summary::-webkit-details-marker,
  & > summary::marker {
    display: none;
    content: "";
  }
  & > summary {
    display: flex;
    align-items: center;
    cursor: pointer;
    ${SubtitleMixin};
    color: ${({ $summaryFgColor }) => $summaryFgColor};
    background-color: ${({ $summaryBgColor }) => $summaryBgColor};
    border-radius: 8px 8px 0 0;
    user-select: none;
  }

  // Apply specific styles to card variants:
  &.${getClassname("card")} > summary {
    padding: ${spacing.XS} ${spacing.M};
    ${(props) => `padding: ${props.$cardPaddingOverride}`}
  }

  // Apply specific styles to accordion variants:
  &.${getClassname("accordion")} > summary {
    padding-bottom: ${spacing.XS};
  }
  &[open].${getClassname("accordion")} > summary {
    border-bottom: 1px solid ${divider};
  }

  @keyframes animateDown {
    0% {
      opacity: 0;
      transform: translatey(-15px);
    }
    100% {
      opacity: 1;
      transform: translatey(0);
    }
  }
`
